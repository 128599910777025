import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Layout from "../../components/layout";
import ContactSidebar from "../../components/ContactSidebar-en";
import AdresseSidebar from "../../components/AdresseSidebar-en";
import AvantApresBlock from "../../components/AvantApresBlock-en";
import KeybodyBlock from "../../components/KeybodyBlock-en";
import VideoBlock from "../../components/VideoBlock-en";
import { FaPlus } from "react-icons/fa";

import Menu from "../../components/Menu-en";
import Footer from "../../components/Footer-en";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { Container, Row, Col } from "glamorous-grid";
import { Helmet } from "react-helmet";
import { FaHome } from "react-icons/fa";

export default function Template({ data }) {
  const {
    portraitKron,
    portraitRihame,
    portraitLaura,
    portraitFossat,
    portraitYlan,
    portraitMarie,
    elodie,
    emilie,
    isabelle
  } = data;

  const Wrapper = g.div({
    maxWidth: `1100px`,
    margin: `0 auto 2.5em`
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `38px`,
    color: `#3c4d68`,
    maxWidth: `700px`,
    margin: `1.5em auto 1em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `49px`,
    "@media(max-width: 767px)": {
      fontSize: `30px`,
      lineHeight: `32px`
    }
  });

  const H2 = g.h2({
    fontSize: `24px`,
    fontWeight: `500`,
    lineHeight: `30px`,
    marginTop: `0`,
    "@media(max-width: 767px)": {
      fontSize: `20px`,
      lineHeight: `25px`
    }
  });

  const LinkWrapper = g.div({
    textAlign: `right`
  });

  const LinkPlus = css({
    color: `#3c4d68`,
    textDecoration: `none`,
    border: `none`,
    width: `100%`
  });

  const cssPlus = css({
    fontSize: `1em`,
    padding: `0 .4em .12em`
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`
      }
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`
    }
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>Medical team | Dr Cédric Kron</title>
          <meta
            name="description"
            content="Dr. Cédric Kron's team, a team of professionals dedicated to obtaining a natural result thanks to the contributions of techniques of medicine and plastic surgery."
          />
          <html lang="en" />
          <link
            rel="alternate"
            hreflang="fr"
            href="https://www.dr-kron.com/en/equipe-cabinet/"
          />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Medical team",
          "item": "https://www.dr-kron.com/en/medical-team/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu current="equipe-cabinet" pathFr="/equipe-cabinet/" />
        <Wrapper>
          <Container css={{ padding: `0 15px !important` }}>
            <Row>
              <Col>
                <div className="page-post">
                  <Title>Medical team</Title>
                </div>
              </Col>
            </Row>

            <Row>
              <Col span={{ xs: 12 / 12, lg: 69 / 100 }}>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <Link
                      to="/en/"
                      {...cssBreadcrumbLink}
                      title="Page d'accueil"
                    >
                      <FaHome
                        css={{
                          fontSize: `1.2em`,
                          paddingBottom: `.2em`,
                          transform: `translateY(5px)`
                        }}
                      />
                    </Link>
                  </BreadcrumbItem>
                  <BreadcrumbItem>Medical team</BreadcrumbItem>
                </Breadcrumb>
                <p className="intro">
                  Dr. Cédric Kron's office is a space dedicated to the overall
                  body care and obtaining a natural result through the combined
                  contributions of medical techniques and plastic surgery. You
                  will be supported by a multidisciplinary team trained around
                  Dr. Kron's expertise.
                </p>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitKron.childImageSharp.fluid}
                      alt="Dr Cédric Kron, Cosmetic and Plastic surgeon in Paris"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Dr. Cedric Kron - Plastic Surgeon</H2>

                    <p css={{ marginBottom: `.1em` }}>
                      Former resident, Gold Medal of Surgery, former Head of
                      Clinic of the Hospitals of Paris, Dr Kron is{" "}
                      <Link to="/en/plastic-surgeon-paris/">
                        qualified in Plastic Surgery, Reconstructive and
                        Aesthetic
                      </Link>
                      . He works as a plastic surgeon and aesthetic doctor and
                      focuses on developing minimally invasive procedures that
                      respond as faithfully as possible to the needs of his
                      patients.
                    </p>

                    <LinkWrapper>
                      <Link to="/en/plastic-surgeon-paris/" {...LinkPlus}>
                        <FaPlus {...cssPlus} />
                        Learn more
                      </Link>
                    </LinkWrapper>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitFossat.childImageSharp.fluid}
                      alt="Dr. Sébastien Fossat - Plastic surgeon"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Dr. Sébastien Fossat - Plastic surgeon</H2>

                    <p>
                      Graduate of Plastic, Reconstructive and Aesthetic Surgery
                      since 2004, Dr. Fossat divided his activity for 10 years
                      between reconstructive and plastic surgery as Deputy Head
                      of the Department of Plastic Surgery at Percy Hospital.
                      Now established in the private sector, he offers plastic
                      surgery consultations in his{" "}
                      <a
                        href="http://drfossat.fr"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Saint-Germain-en-Laye’s practice
                      </a>{" "}
                      as well as in Paris in collaboration with Dr. Kron.
                    </p>
                  </Col>
                </Row>
                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={isabelle.childImageSharp.fluid}
                      alt="Isabelle, operating aid of Dr. Cédric Kron"
                    />
                  </Col>
                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Isabelle, operating aid of Dr. Kron</H2>
                    <p>
                      Operating room nurse (holder of the National diploma),
                      specialized in Plastic Surgery, Aesthetic and
                      Reconstructive surgery, Isabelle has been assisting Dr.
                      Cédric Kron for several years in all the surgeries he
                      performs in Parisian clinics.
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitRihame.childImageSharp.fluid}
                      alt="Rihame - Director of the Medical practice"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Rihame - Director of the Medical practice</H2>
                    <p>
                      Dr. Kron's personal medical assistant for many years,
                      Rihame will answer all your questions, will be at your
                      service and will coordinate the entire team around your
                      expectations. She will also accompany you as part of the{" "}
                      <Link to="/en/face/face-treatment-diagnosis/">
                        diagnosis for face treatments
                      </Link>{" "}
                      offered in our practice.
                    </p>
                  </Col>
                </Row>
                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitLaura.childImageSharp.fluid}
                      alt="Laura Courbon - qualified nurse (holder of the National diploma) "
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>
                      Laura - State certified nurse (IDE) – Post-operative care
                    </H2>

                    <p>
                      State-certified nurse (holder of the French National
                      diploma), Laura offers post-operative care with Dr. Kron.
                      Trained for both Coolsculpting and EMSculpt treatments,
                      she will help you determine which treatment is best for
                      you as part of the{" "}
                      <Link to="/en/body-contouring/consultation/">
                        Body Contouring consultation
                      </Link>
                      . Laura is also trained for peeling, laser, radiofrequency
                      and mesotherapy treatments available at our practice.
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={elodie.childImageSharp.fluid}
                      alt="Élodie - Medical Assistant"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Élodie - Medical Assistant</H2>

                    <p>
                      Élodie will answer all your questions about the surgical
                      and medical treatments we offer. Holder of a beautician
                      degree and trained in{" "}
                      <Link to="/en/face/face-treatment-diagnosis/">
                        face treatments diagnosis
                      </Link>{" "}
                      tools such as Visia Complexion Analysis and Skinscope LED,
                      she will help you define the best care available for your
                      skin. Élodie is also in charge of our social media
                      accounts (
                      <a
                        href="https://www.instagram.com/docteurkron/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Instagram
                      </a>
                      ,{" "}
                      <a
                        href="https://www.facebook.com/DrCedricKron"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Facebook
                      </a>
                      ).
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitMarie.childImageSharp.fluid}
                      alt="Marie, State certified nurse (IDE)"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Marie - State certified nurse (IDE)</H2>

                    <p>
                      State-certified nurse (holder of the French National
                      diploma), Marie takes care of post-operative care
                      alongside Dr. Kron and Laura. Trained in Coolsculpting and{" "}
                      <Link to="/en/body-contouring/emsculpt/">
                        EMSculpt treatment
                      </Link>
                      , she will help you determine which treatment is best for
                      you as part of the Body Contouring consultation. Marie is
                      also trained in all the techniques of peeling, laser,
                      radiofrequency and mesotherapy proposed within the firm.
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={emilie.childImageSharp.fluid}
                      alt="Émilie - Medical assistant & sports coach"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Émilie - Medical assistant & sports coach</H2>

                    <p>
                      As a medical assistant and sports coach specialized in
                      XBody sessions, EMSculpt treatment and the{" "}
                      <Link to="/en/body-contouring/keybody-lipo/">
                        Keybody-Lipo remodeling protocol
                      </Link>
                      , Emilie accompanies patients in terms of body shape,
                      muscular tonicity, cardiovascular and respiratory health.
                      She will answer all your questions as part of the Body
                      contouring consultation. She supervises XBody training
                      sessions on Wednesdays and Fridays.
                    </p>
                  </Col>
                </Row>

                <Row css={{ marginBottom: `30px` }}>
                  <Col span={{ xs: 3 / 12 }}>
                    <Img
                      fluid={portraitYlan.childImageSharp.fluid}
                      alt="Ylan - Sports Coach specialized in Electric Muscle
                      Stimulation training"
                    />
                  </Col>

                  <Col span={{ xs: 9 / 12 }}>
                    <H2>Ylan - Sports Coach specialized in EMS training</H2>
                    <p>
                      Sports coach, Ylan is a specialist of Electric Muscle
                      Stimulation (EMS) training. He oversees the{" "}
                      <Link to="/en/body-contouring/xbody-reshape-body-ems/">
                        XBody sessions
                      </Link>{" "}
                      held in the office on Tuesdays and Thursdays.
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={{ xs: 12 / 12, lg: 31 / 100 }}>
                <ContactSidebar about="the medical team" />
                <AdresseSidebar />
                <AvantApresBlock />
                <KeybodyBlock />
                <VideoBlock />
              </Col>
            </Row>
          </Container>
        </Wrapper>
        <Footer />
      </g.Div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query EquipeEn {
    portraitKron: file(relativePath: { eq: "images/portrait-dr-kron.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitFossat: file(
      relativePath: {
        eq: "images/dr-sebastien-fossat-chirurgien-esthetique.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitRihame: file(relativePath: { eq: "images/rihame-kharrat.jpg" }) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitLaura: file(
      relativePath: { eq: "images/laura-courbon-infirmiere-etat.jpg" }
    ) {
      childImageSharp {
        resize(width: 1500, height: 1500) {
          src
        }
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitMarie: file(
      relativePath: { eq: "images/marie-infirmiere-diplomee-etat.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitYlan: file(relativePath: { eq: "images/ylan-coach-sportif.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    elodie: file(relativePath: { eq: "images/elodie.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    emilie: file(
      relativePath: {
        eq: "images/emilie-coach-sportif-assistante-medicale.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    isabelle: file(
      relativePath: { eq: "images/isabelle-aide-operatoire.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 300) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
