import React from "react";
import { StaticQuery, graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Img from "gatsby-image";
import { FaPlus } from "react-icons/fa";

const A = g.a({
  textDecoration: `none`
});

const Div = g.div({
  borderRadius: `4px`,
  backgroundColor: `white`,
  color: `#1f1f1f`,
  border: `1px solid rgba(73,105,126,0.08)`,
  boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
  margin: `0 0 2em`,
  transition: `all 0.15s ease-in-out`,
  ":hover": {
    border: `1px solid rgba(73,105,126,0.18)`,
    boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
    transition: `all 0.1s ease-in-out`,
    transform: `translateY(-2px)`
  }
});

const Title = g.div({
  padding: `12px 11px 7px`,
  margin: `0 auto 0em`,
  letterSpacing: `0.05em`,
  fontSize: `1.15em`,
  color: `#49697e`,
  fontWeight: `bold`
});

const cssImage = css({
  width: `100%`,
  borderRadius: `4px`
});

const P = g.p({
  margin: `0 0 .1em`,
  fontSize: `.95em`,
  padding: `0 .85em 0`,
  lineHeight: `1.445em`
});

const More = g.div({
  color: `#49697e`,
  fontSize: `14px`,
  padding: `.2em 1em .6em`,
  textAlign: `right`,
  margin: `0.3em 0 .4em`
});

export default () => (
  <StaticQuery
    query={graphql`
      query keybodyskinEn {
        file(relativePath: { eq: "images/keybody.jpg" }) {
          childImageSharp {
            resize(width: 1500, height: 1500) {
              src
            }
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <A href="https://www.keybody.fr/">
        <Div>
          <Img
            fluid={data.file.childImageSharp.fluid}
            {...cssImage}
            alt="Keybody, a center dedicated to body reshaping with EMSculpt, XBody and Cryolypolysis Coolsculpting in Paris"
          />
          <Title>Keybody</Title>
          <P>
            A center dedicated to body reshaping with EMSculpt, XBody and
            Cryolypolysis Coolsculpting in Paris.
          </P>
          <More>
            <FaPlus
              css={{
                fontSize: `1em`,
                padding: `0 .3em 0.1em`,
                transform: `translateY(3px)`
              }}
            />
            Learn more
          </More>
        </Div>
      </A>
    )}
  />
);
